import { ColorOverlayFilter } from 'pixi-filters';
import { Spine } from 'pixi-spine';
import { Container, Graphics, Loader, Sprite, Spritesheet, Texture } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';
import { formatNumber } from '@phoenix7dev/utils-fe';

import {
  CardAbilitiesPattern,
  CardClass,
  CardLevel,
  ISongs,
  SlotId,
  eventPatternMappingsAnimationEnd,
  eventPatternMappingsAnimationStart,
  soundsPerCardLevel,
} from '../../config';
import { EventTypes } from '../../global.d';
import {
  setBetResult,
  setCardsDisappeared,
  setEnabledCards,
  setIsAutoSpins,
  setIsPopupOpened,
  setIsTurboSpin,
  setSelectedRewardCard,
  setUnlockedBackgrounds,
} from '../../gql/cache';
import { Logic } from '../../logic';
import { States } from '../../logic/config';
import { ResourceTypes } from '../../resources.d';
import { countCoins, getBetResult, normalizeCoins } from '../../utils';
import { ModalService } from '../ModalService';
import type { IEntryAnimation } from '../animations/d';
import { CardInfo } from '../collection/cardInfo';
import { abilitiesPreferences } from '../collection/collection.model';
import { TextField } from '../components/TextField';
import { ViewContainer } from '../components/ViewContainer';
import { SLOT_HEIGHT, SLOT_WIDTH, eventManager } from '../config';
import type { ICardAbility } from '../d';
import { TimeActions } from '../tooltips/timeActions';
import { tooltipsStore } from '../tooltips/tooltip';

import { bgByRewardName } from './cardBackByLevel';
import { cardAbilitiesLabelTextStyle, cardAbilitiesValueTextStyle, cardPriceTextStyle } from './textStyles';

export class Card extends ViewContainer {
  private id: number;

  private cardName: SlotId;

  private cardClass: CardClass;

  private cardLevel: CardLevel;

  private cardAbilities!: Container;

  private cardPrice: number;

  private cardPriceText: TextField;

  private isOpened: boolean;

  private cardFrontBackground: Sprite;

  private cardFrontText: Sprite;

  private cardBack: Sprite;

  private cardFlipAnimation: Spine;

  private cardBacklightAnimation: Spine;

  private cardFrameIdleAnimation: Spine;

  private cardFrameDisappear: Spine;

  private cardSymbol: Spine | null;

  private cardPriceContainer: Container;
  private showNewLabelTimer: NodeJS.Timeout | undefined;

  private masterAbilityValue!: TextField;

  private masterAbilityLabel!: TextField;

  private freeAbilityValue!: TextField;

  private freeAbilityLabel!: TextField;

  private multiplyAbilityLabel!: TextField;

  private multiplyAbilityValue!: TextField;

  private upgradeAbilityLabel!: TextField;

  private upgradeAbilityValue!: TextField;

  private multiplyAllAbilityLabel!: TextField;

  private multiplyAllAbilityValue!: TextField;

  private upgradeAllAbilityLabel!: TextField;

  private upgradeAllAbilityValue!: TextField;

  private cardAbilitiesSpritesheet = Loader.shared.resources['cardAbilities']?.spritesheet?.textures;

  private abilitiesAnimated = Loader.shared.resources!['abilities_animated']!.spineData;

  private priceContainerGraphic!: Graphics;

  private labelNew = this.getLabelNew();

  private cardResourceByNameMemo: { [key: string]: Spritesheet | undefined } = {};

  private disappeared = false;

  get getCardBackBg(): Texture {
    const rewardName = setSelectedRewardCard();

    return bgByRewardName[rewardName]()!;
  }

  constructor(
    id: number,
    cardName: SlotId,
    cardClass: CardClass,
    cardLevel: CardLevel,
    cardAbilities: ICardAbility[],
    cardPrice: number,
  ) {
    super();
    this.visible = true;
    this.zIndex = 1;
    this.isOpened = false;
    this.name = cardName;
    this.id = id;
    this.cardName = cardName;
    this.cardClass = cardClass;
    this.cardLevel = cardLevel;
    this.cardPrice = cardPrice;
    this.initAbilityTextFields();
    this.cardPriceText = this.initCardPriceText(cardPrice);
    this.initCardAbilities(cardAbilities);
    this.cardPriceContainer = this.initCardPriceContainer();
    this.cardFrontBackground = this.initCardFrontBackground();
    this.cardFrontText = this.initCardFrontText();
    this.cardSymbol = this.initCardSymbol();
    this.cardBacklightAnimation = this.initCardBacklightAnimation();
    this.cardFrameIdleAnimation = this.initCardFrameIdleAnimation();
    this.cardFrameDisappear = this.initCardFrameDisappearAnimation();

    this.cardFrontBackground.addChild(
      this.cardBacklightAnimation,
      this.cardFrameIdleAnimation,
      this.cardSymbol,
      this.cardFrontText,
      this.cardPriceContainer,
      this.cardAbilities,
    );

    this.cardBack = this.initCardBack();
    this.cardFlipAnimation = this.initCardFlipAnimation();

    this.addChild(this.cardFlipAnimation, this.labelNew);
    eventManager.addListener(EventTypes.FORCE_FLIP, () => {
      if (!this.isOpened) {
        this.handleFlipCard();
      }
    });
    eventManager.addListener(EventTypes.UPDATE_CARD, (props) => {
      this.updateCard(props);
    });
    eventManager.addListener(EventTypes.HIDE_CARD, () => {
      this.hideCard();
    });
    eventManager.addListener(EventTypes.HANDLE_CARD_WIN_ANIMATION, () => {
      if (Logic.the.state.name === States.AFTER_WIN) {
        this.handleWinAnimation();
      }
    });
    eventManager.addListener(EventTypes.UPDATE_CARD_BACKGROUND, () => {
      const newCardBackBg = this.getCardBackBg;

      if (newCardBackBg) {
        this.cardBack.texture = newCardBackBg;
      }
    });
    eventManager.addListener(EventTypes.CARDS_INITIALIZED, () => {
      this.cardBack.interactive = setIsAutoSpins() ? false : true;
    });
    eventManager.on(EventTypes.SET_IS_AUTO_SPINS, (isAutoSpins: boolean) => {
      this.cardBack.interactive = !isAutoSpins && !this.disappeared;
    });
  }

  private initAbilityTextFields(): void {
    this.masterAbilityLabel = new TextField(`MASTER`, 270, 100, cardAbilitiesLabelTextStyle);
    this.masterAbilityValue = new TextField(``, 270, 100, cardAbilitiesValueTextStyle);
    this.freeAbilityLabel = new TextField(``, 270, 100, cardAbilitiesLabelTextStyle);
    this.freeAbilityValue = new TextField(``, 270, 100, cardAbilitiesValueTextStyle);
    this.upgradeAbilityLabel = new TextField(``, 270, 100, cardAbilitiesLabelTextStyle);
    this.upgradeAbilityValue = new TextField(``, 270, 100, cardAbilitiesValueTextStyle);
    this.multiplyAbilityLabel = new TextField(``, 270, 100, cardAbilitiesLabelTextStyle);
    this.multiplyAbilityValue = new TextField(``, 270, 100, cardAbilitiesValueTextStyle);
    this.upgradeAllAbilityLabel = new TextField(`ALL`, 270, 100, cardAbilitiesLabelTextStyle);
    this.upgradeAllAbilityValue = new TextField(``, 270, 100, cardAbilitiesValueTextStyle);
    this.multiplyAllAbilityLabel = new TextField(`ALL`, 270, 100, cardAbilitiesLabelTextStyle);
    this.multiplyAllAbilityValue = new TextField(``, 270, 100, cardAbilitiesValueTextStyle);
  }

  private initCardBack(): Sprite {
    const cardBack = this.getCardBackBg;
    const card = new Sprite(cardBack || bgByRewardName.Cm());
    card.width = SLOT_WIDTH;
    card.height = SLOT_HEIGHT;
    card.name = 'CARD_BACK';
    card.anchor.set(0.5);
    card.interactive = false;
    card.buttonMode = true;
    card.on('click', (event) => {
      event.data.originalEvent.preventDefault();
      this.isOpened ? this.showInfo() : this.handleFlipCard();
    });
    card.on('touchstart', (event) => {
      event.data.originalEvent.preventDefault();
      this.isOpened ? this.showInfo() : this.handleFlipCard();
    });
    return card;
  }

  private showInfo(): void {
    if (setIsAutoSpins() || setIsPopupOpened() || Logic.the.state.name !== States.IDLE) return;
    setTimeout(() => {
      ModalService.the.open(new CardInfo(this.cardLevel, this.cardClass), { title: 'collection' });
    }, 0);
  }

  private initCardFrontBackground(): Sprite {
    const texture = Loader.shared.resources[this.cardName]!.spritesheet!.textures[`${this.cardName}_background.png`];
    const card = new Sprite(texture);
    card.name = 'CARD_FRONT';
    card.anchor.set(0.5);
    return card;
  }

  private initCardFrontText(): Sprite {
    const texture = Loader.shared.resources[this.cardName]!.spritesheet!.textures[`${this.cardName}_text.png`];
    const card = new Sprite(texture);
    card.name = 'CARD_FRONT_TEXT';
    card.anchor.set(0.5);
    return card;
  }

  private updateCardPriceText(cardPrice: number): void {
    const {
      balance: {
        settled: { currency },
      },
      bet: { coinAmount },
    } = getBetResult(setBetResult());
    const value = formatNumber({
      currency: currency,
      value: normalizeCoins(
        countCoins({
          totalAmount: cardPrice,
          coinAmount,
        }),
      ),
      showCurrency: false,
    });
    this.cardPriceText.setText(value);
  }

  private initCardPriceText(cardPrice: number): TextField {
    const {
      balance: {
        settled: { currency },
      },
      bet: { coinAmount },
    } = getBetResult(setBetResult());
    const price = new TextField(
      formatNumber({
        currency: currency,
        value: normalizeCoins(
          countCoins({
            totalAmount: cardPrice,
            coinAmount,
          }),
        ),
        showCurrency: false,
      }),
      270,
      100,
      cardPriceTextStyle,
    );
    price.text.anchor.set(0.5);

    return price;
  }

  private initCardPriceContainer(): Container {
    const container = new Container();
    container.visible = false;
    container.y = 235;
    this.cardPriceText.text.x = container.width / 2;
    this.cardPriceText.text.anchor.set(0.5);
    const graphics = new Graphics();
    const graphicsWidth = this.cardPriceText.text.width + 24;
    const graphicsHeight = 46;
    graphics.lineStyle(3, 0x000000);
    graphics.beginFill(0x000000, 0.5);
    graphics.drawRoundedRect(-graphicsWidth / 2, -23, graphicsWidth, graphicsHeight, 8);
    graphics.endFill();
    container.addChild(graphics);
    this.priceContainerGraphic = graphics;
    container.addChild(this.cardPriceText.getText());
    return container;
  }

  private updateCardPriceContainer(): void {
    this.priceContainerGraphic.clear();
    const graphicsWidth = this.cardPriceText.text.width + 24;
    const graphicsHeight = 46;
    this.priceContainerGraphic.lineStyle(3, 0x000000);
    this.priceContainerGraphic.beginFill(0x000000, 0.5);
    this.priceContainerGraphic.drawRoundedRect(-graphicsWidth / 2, -23, graphicsWidth, graphicsHeight, 8);
    this.priceContainerGraphic.endFill();
    this.cardPriceContainer.visible = !!this.cardPrice;
  }

  private initCardFlipAnimation(): Spine {
    const animation = new Spine(Loader.shared.resources!['card_frame_open']!.spineData!);
    const frontPlaceholder = animation.skeleton.findSlot('place_holder_front').currentSprite as Sprite;
    frontPlaceholder.texture = Texture.EMPTY;
    frontPlaceholder.addChild(this.cardFrameDisappear);
    const backPlaceholder = animation.skeleton.findSlot('place_holder_back').currentSprite as Sprite;
    backPlaceholder.texture = Texture.EMPTY;
    backPlaceholder.addChild(this.cardBack);
    animation.state.addListener({
      start: (entry: IEntryAnimation) => {
        if (entry.animation.name.startsWith('card_open')) {
          this.isOpened = true;

          this.showPriceContainer();
        }
      },
      complete: (entry: IEntryAnimation) => {
        if (entry.animation.name.startsWith('card_open')) {
          eventManager.emit(EventTypes.CARD_IS_OPENED, this.id);
        }
      },
    });

    return animation;
  }

  private initCardBacklightAnimation(): Spine {
    const animation = new Spine(Loader.shared.resources!['card_frame_backlight']!.spineData!);
    animation.name = 'cardBacklightAnimation';
    animation.state.setAnimation(0, `card_frame_backlight_${this.cardLevel}`, true);
    return animation;
  }

  private initCardFrameIdleAnimation(): Spine {
    const animation = new Spine(Loader.shared.resources!['card_frame_idle']!.spineData!);
    animation.name = 'cardFrameIdleAnimation';
    animation.state.setAnimation(0, `card_frame_idle_${this.cardLevel}`, true);
    return animation;
  }

  private initCardFrameDisappearAnimation(): Spine {
    const animation = new Spine(Loader.shared.resources!['card_frame_disappear']!.spineData!);
    animation.name = 'cardFrameDisappearAnimation';
    const placeholder = animation.skeleton.findSlot('place_holder').currentSprite as Sprite;
    placeholder.texture = Texture.EMPTY;
    placeholder.addChild(this.cardFrontBackground);
    return animation;
  }

  private initCardSymbol(): Spine {
    const symbol = new Spine(Loader.shared.resources![`symbolAnimated_${this.cardClass}`]!.spineData!);
    symbol.name = 'cardSymbolAnimation';
    symbol.state.setAnimation(0, 'idle', true); // win activation idle
    symbol.state.addListener({
      complete: (entry: IEntryAnimation) => {
        switch (entry.animation.name) {
          case 'activation':
          case 'win': {
            symbol.state.setAnimation(0, 'idle', true);
            break;
          }
          default:
            break;
        }
      },
    });
    return symbol;
  }

  private initCardAbilities(abilities: ICardAbility[]): void {
    let abilityContainer: Container;
    if (this.cardAbilities) {
      abilityContainer = this.cardAbilities;
      abilityContainer.children.forEach((child) => {
        if (child.name.startsWith('abilitySpine')) {
          child.removeAllListeners();
          child.destroy();
        } else {
          child.destroy();
        }
      });
      abilityContainer.removeChildren();
    } else {
      abilityContainer = new Container();
      this.cardAbilities = abilityContainer;
    }
    abilityContainer.y = 150;
    abilities?.forEach((item, index) => {
      const animatedAbility = new Spine(this.abilitiesAnimated!);
      animatedAbility.name = 'abilitySpine ' + item.pattern;
      let label!: TextField;
      let value!: TextField;

      switch (item.pattern) {
        case CardAbilitiesPattern.Np: {
          this.freeAbilityValue.text.text = `+${item.value}`;
          value = this.freeAbilityValue;
          label = this.freeAbilityLabel;
          break;
        }
        case CardAbilitiesPattern.NM: {
          this.masterAbilityValue.text.text = `+${item.value}`;
          value = this.masterAbilityValue;
          label = this.masterAbilityLabel;
          break;
        }
        case CardAbilitiesPattern.Nx: {
          this.multiplyAbilityValue.text.text = `x${item.value}`;
          value = this.multiplyAbilityValue;
          label = this.multiplyAbilityLabel;
          break;
        }
        case CardAbilitiesPattern.Nup: {
          this.upgradeAbilityValue.text.text = `${item.value}`;
          value = this.upgradeAbilityValue;
          label = this.upgradeAbilityLabel;
          break;
        }
        case CardAbilitiesPattern.NxA: {
          this.multiplyAllAbilityValue.text.text = `x${item.value}`;
          value = this.multiplyAllAbilityValue;
          label = this.multiplyAllAbilityLabel;
          break;
        }
        case CardAbilitiesPattern.NupA: {
          this.upgradeAllAbilityValue.text.text = `${item.value}`;
          value = this.upgradeAllAbilityValue;
          label = this.upgradeAllAbilityLabel;
          break;
        }
        default:
          break;
      }
      value.text.anchor.set(0.5);

      label.setStyle({
        ...cardAbilitiesLabelTextStyle,
        fill: abilitiesPreferences[item.pattern].color,
      });
      label.text.anchor.set(0.5);
      label.text.y = 27;
      const abl = new Sprite(this.cardAbilitiesSpritesheet![`${item.pattern}`]);
      abl.name = 'abilitySprite ' + item.pattern;
      abl.anchor.set(0.5);
      abl.name = item.pattern;
      abl.x = SLOT_WIDTH / 2 - index * 70 - 45;
      animatedAbility.x = abl.x;
      if (value.getText()) {
        abl.addChild(value.getText());
      }
      if (label.getText()) {
        abl.addChild(label.getText());
      }
      abilityContainer.addChild(animatedAbility);
      abilityContainer.addChild(abl);
      const event = eventPatternMappingsAnimationStart[
        (item.pattern + '_' + (this.id + 1)) as keyof typeof eventPatternMappingsAnimationStart
      ] as unknown as string as EventTypes;
      eventManager.addListener(event, (pattern, cardIndex) => {
        if (this.id === cardIndex && item.pattern === pattern) {
          this.startAnimationPerCardClass();
          animatedAbility.state.setAnimation(0, `activation_${pattern}`, false);
          AudioApi.play({ type: ISongs.Ability_Activate });
          animatedAbility.state.addListener({
            complete: (entry: IEntryAnimation) => {
              switch (entry.animation.name) {
                case `activation_${pattern}`: {
                  break;
                }
                default:
                  break;
              }
            },
            event: (_entry, event) => {
              switch (event.data.name) {
                case `deactivation_${pattern}`:
                  abl.filters = [new ColorOverlayFilter(0x000000, 0.5)];
                  eventManager.emit(
                    eventPatternMappingsAnimationEnd[
                      item.pattern as keyof typeof eventPatternMappingsAnimationEnd
                    ] as unknown as string,
                    this.id,
                  );
                  break;
                default:
                  break;
              }
            },
          });
        }
        eventManager.removeListener(event);
      });
    });
  }

  private showPriceContainer(): void {
    if (this.cardPrice > 0) {
      this.cardPriceContainer.visible = true;
    }
  }

  private handleWinAnimation(): void {
    if (this.cardPrice > 0) {
      this.cardSymbol!.state.setAnimation(0, 'win', false);
    }
  }

  public updateCard(props: {
    id: number;
    cardName: SlotId;
    cardClass: CardClass;
    cardLevel: CardLevel;
    cardPrice: number;
  }): void {
    if (props.id === this.id) {
      const cardChanged = this.cardName !== props.cardName;
      this.cardName = props.cardName;
      this.cardClass = props.cardClass;
      this.cardPrice = props.cardPrice;
      this.cardLevel = props.cardLevel;
      this.updateCardPriceText(props.cardPrice);
      this.updateCardPriceContainer();
      this.cardBacklightAnimation.state.setAnimation(0, `card_frame_backlight_${props.cardLevel}`, true);
      this.cardFrameIdleAnimation.state.setAnimation(0, `card_frame_idle_${props.cardLevel}`, true);
      if (this.cardPrice > 0) {
        setTimeout(() => (this.cardPriceContainer.visible = true));
      }
      if (cardChanged) {
        const resource = this.getCachedResource();
        this.cardFrontBackground.texture = resource!.textures[`${this.cardName}_background.png`]!;
        this.cardFrontText.texture = resource!.textures[`${this.cardName}_text.png`]!;
      }
    }
  }

  private hideCard(): void {
    this.disappeared = true;
    setCardsDisappeared(true);
    this.cardBack.interactive = false;
    const isTurboSpin = setIsTurboSpin();
    this.cardFrameDisappear.state.timeScale = isTurboSpin ? 3 : 1.5;
    this.cardFrameDisappear.state.setAnimation(0, `card_frame_disappear_${this.cardLevel}`, false);
    this.hideLabelNew();
  }

  public resetCard(
    id: number,
    cardName: SlotId,
    cardClass: CardClass,
    cardLevel: CardLevel,
    cardAbilities: ICardAbility[],
    cardPrice: number,
  ): void {
    this.disappeared = false;
    const cardChanged = this.cardName !== cardName;
    this.id = id;
    this.cardName = cardName;
    this.cardClass = cardClass;
    this.cardLevel = cardLevel;
    this.cardPrice = cardPrice;
    this.visible = true;
    this.isOpened = false;
    this.updateCardPriceText(cardPrice);
    this.updateCardPriceContainer();
    // clean up card symbol spine
    this.cardSymbol!.removeAllListeners();
    this.cardFrontBackground.removeChild(this.cardSymbol!);
    this.cardSymbol = null;
    this.cardSymbol = this.initCardSymbol();

    const cardBack = this.getCardBackBg;
    this.cardBack.texture = cardBack || bgByRewardName.Cm();
    this.cardBack.interactive = false;
    this.initCardAbilities(cardAbilities);

    this.cardFrontBackground.addChildAt(this.cardSymbol, 2);
    if (this.cardPrice > 0) {
      setTimeout(() => (this.cardPriceContainer.visible = true));
    }
    if (cardChanged) {
      const resource = this.getCachedResource();
      this.cardFrontBackground.texture = resource!.textures[`${this.cardName}_background.png`]!;
      this.cardFrontText.texture = resource!.textures[`${this.cardName}_text.png`]!;
    }

    this.cardBacklightAnimation.state.setAnimation(0, `card_frame_backlight_${this.cardLevel}`, true);
    this.cardFrameIdleAnimation.state.setAnimation(0, `card_frame_idle_${this.cardLevel}`, true);
    this.cardFlipAnimation.state.addEmptyAnimation(0, 0, 0);
    this.cardFrameDisappear.state.addEmptyAnimation(0, 0, 0);
    this.hideLabelNew();
  }

  public handleFlipCard(): void {
    const isTurboSpin = setIsTurboSpin();
    this.cardFlipAnimation.state.timeScale = isTurboSpin ? 3 : 1.5;
    this.cardFlipAnimation.state.setAnimation(0, `card_open_${this.cardLevel}`, false);
    const sound = soundsPerCardLevel[this.cardLevel](setIsTurboSpin());
    if (AudioApi.isPlaying(sound)) {
      AudioApi.stop({ type: sound });
    }
    AudioApi.play({ type: sound });

    TimeActions.the.triggerFlipCard();
    tooltipsStore.hideAll();

    const storedUnlockedCards = setEnabledCards();

    if (storedUnlockedCards.includes(this.cardName)) return;

    TimeActions.the.getNewCard();
    this.showLabelNew();
    eventManager.emit(EventTypes.GET_NEW_CARD, this.cardName);

    setEnabledCards([this.cardName, ...storedUnlockedCards]);

    if (this.isOpenNewReward()) {
      setUnlockedBackgrounds([...setUnlockedBackgrounds(), this.cardLevel]);
      eventManager.emit(EventTypes.UPDATE_BONUS_REWARDS, this.cardLevel);
      TimeActions.the.achieveNewReward();
    }
  }

  private isOpenNewReward(): boolean {
    if (setUnlockedBackgrounds().includes(this.cardLevel)) return false;

    const unlockedCards = setEnabledCards();
    const numberOfAllLevelCard = Object.values(CardClass).length;
    const numberOfUnlockedLevelCard = unlockedCards.filter((slotId) => slotId.includes(this.cardLevel)).length;

    return numberOfUnlockedLevelCard === numberOfAllLevelCard;
  }

  private startAnimationPerCardClass(): void {
    switch (this.cardClass) {
      case CardClass.HP1:
        this.cardSymbol!.state.setAnimation(0, 'activation', false);
        if (AudioApi.isPlaying(ISongs.High_Pay_Symbol_1_Activation)) {
          AudioApi.stop({ type: ISongs.High_Pay_Symbol_1_Activation });
        }
        AudioApi.play({ type: ISongs.High_Pay_Symbol_1_Activation });
        break;
      case CardClass.HP2:
        this.cardSymbol!.state.setAnimation(0, 'activation', false);
        if (AudioApi.isPlaying(ISongs.High_Pay_Symbol_2_Activation)) {
          AudioApi.stop({ type: ISongs.High_Pay_Symbol_2_Activation });
        }
        AudioApi.play({ type: ISongs.High_Pay_Symbol_2_Activation });
        break;
      case CardClass.HP3:
        this.cardSymbol!.state.setAnimation(0, 'activation', false);
        if (AudioApi.isPlaying(ISongs.High_Pay_Symbol_3_Activation)) {
          AudioApi.stop({ type: ISongs.High_Pay_Symbol_3_Activation });
        }
        AudioApi.play({ type: ISongs.High_Pay_Symbol_3_Activation });
        break;
      case CardClass.HE1:
        this.cardSymbol!.state.setAnimation(0, 'activation', false);
        if (AudioApi.isPlaying(ISongs.Hero_Symbol_1_Activation)) {
          AudioApi.stop({ type: ISongs.Hero_Symbol_1_Activation });
        }
        AudioApi.play({ type: ISongs.Hero_Symbol_1_Activation });
        break;
      case CardClass.HE2:
        this.cardSymbol!.state.setAnimation(0, 'activation', false);
        if (AudioApi.isPlaying(ISongs.Hero_Symbol_2_Activation)) {
          AudioApi.stop({ type: ISongs.Hero_Symbol_2_Activation });
        }
        AudioApi.play({ type: ISongs.Hero_Symbol_2_Activation });
        break;
      case CardClass.HE3:
        this.cardSymbol!.state.setAnimation(0, 'activation', false);
        if (AudioApi.isPlaying(ISongs.Hero_Symbol_3_Activation)) {
          AudioApi.stop({ type: ISongs.Hero_Symbol_3_Activation });
        }
        AudioApi.play({ type: ISongs.Hero_Symbol_3_Activation });
        break;
      case CardClass.TCM:
        this.cardSymbol!.state.setAnimation(0, 'activation', false);
        if (AudioApi.isPlaying(ISongs.CDM_Symbol_Activation)) {
          AudioApi.stop({ type: ISongs.CDM_Symbol_Activation });
        }
        AudioApi.play({ type: ISongs.CDM_Symbol_Activation });
        break;

      default:
        break;
    }
  }

  private getLabelNew(): Sprite {
    const newIcon = new Sprite(Texture.from(ResourceTypes.newIcon));
    newIcon.scale.set(2);
    newIcon.x = 75;
    newIcon.y = -210;
    newIcon.visible = false;
    newIcon.name = 'labelNew';

    return newIcon;
  }

  showLabelNew() {
    this.showNewLabelTimer = setTimeout(() => (this.labelNew.visible = true), 1000);
  }
  hideLabelNew() {
    clearTimeout(this.showNewLabelTimer);
    this.labelNew.visible = false;
  }

  getCachedResource() {
    if (!this.cardResourceByNameMemo[this.cardName]) {
      const resource = Loader.shared.resources[this.cardName]!.spritesheet;
      if (resource) {
        this.cardResourceByNameMemo[this.cardName] = resource;
      } else {
        return null;
      }
    }
    return this.cardResourceByNameMemo[this.cardName];
  }
}
